#appointment-book-container {
  h1,
  h2,
  h3 {
    font-family: $font--playfair-display !important;
  }
  [class*='subhead'] {
    font-family: $font--roboto !important;
  }
}

.appt-book {
  padding: 0 5%;
  &.services {
    .appt-book-content-header {
      .total_container {
        height: auto;
        border: none;
        .total_cost {
          width: 100%;
          border-right: none;
          border-bottom: 1px solid $color-gray-light;
          .total_cost_text,
          .price {
            float: none;
          }
        }
        .total_text {
          padding-right: 3px;
          width: 100%;
          height: 75px;
          line-height: 50px;
          text-align: center;
          border-bottom: 1px solid $color-gray-light;
          .total_time_text,
          .minutes {
            float: none;
          }
        }
        .total_cost,
        .total_text {
          height: 75px;
        }
      }
    }
  }
  #appt-book-datepicker-calendar-container {
    th {
      padding: 10px 0 10px;
    }
  }
  .appointment-select {
    .appointments-container {
      .sign-in-container {
        .sign-in-form-container {
          input {
            &.login__submit {
              border-radius: 5px;
            }
          }
        }
      }
      .book-appt-container {
        .registration__privacy-policy-list {
          span {
            font-family: $font--roboto;
            font-size: 12px;
            font-variant: normal;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 18px;
          }
        }
      }
    }
  }
  #confirmation {
    .confirmation-container {
      .appointment-info-content {
        min-height: 210px;
        .appointment-info-detail {
          span {
            font-family: $font--roboto;
          }
        }
      }
    }
  }
  #account-my-appointments {
    .appointments-buttons {
      .cancel-appt-btn,
      .change-appt-btn {
        height: auto;
      }
    }
    .appt-booking-links {
      a {
        &.button {
          height: auto;
        }
      }
    }
  }
  #appt-book-my-appointments-no-appts {
    #appt-book-btn {
      height: auto;
    }
  }
}

.appt-book-overlay {
  height: auto;
  &.appt-book-book-conflict-overlay,
  &.appt-book-services-overlay,
  &.appt-book-services-unavailabe-overlay {
    height: auto;
    .overlay-content-container {
      height: auto;
      .overlay-content {
        height: auto;
      }
    }
  }
}

#appointments {
  .appt-book-overlay {
    .overlay-content-container {
      &.appointment-canceled {
        height: auto;
        .overlay_container {
          height: auto;
          #confirm-cancel {
            height: auto;
          }
          #appt-canceled {
            height: auto;
          }
        }
      }
    }
  }
}
