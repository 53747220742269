.sign-in-page {
  .return-user,
  .new-account {
    &__item {
      &.no-account,
      &.account-present {
        padding-top: 10px;
      }
    }
    &__container {
      h2.social-login__divider {
        &:before {
          @include breakpoint($landscape-up) {
            height: 65%;
            top: 0;
            margin-top: 10px;
          }
        }
        span.inner {
          font-family: $font--roboto;
          @include breakpoint($landscape-up) {
            top: 33%;
            left: 48%;
            margin-left: 3px;
          }
        }
      }
      .first-name,
      .last-name {
        margin: 0;
      }
    }
  }
}

.account-overview__section {
  .account-overview__container {
    .section-content {
      .profile-info {
        div.account-profile__connected-accounts {
          text-transform: none;
        }
        span.label {
          text-transform: none;
        }
      }
    }
  }
}

.ac_list {
  border: 1px solid $color-medium-gray;
  overflow: hidden;
  padding: 0;
  width: 100%;
  z-index: 20;
  ul {
    height: auto;
    list-style: outside none none;
    margin: 0;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    width: 100%;
    li {
      border-top: 1px solid $color-medium-gray;
      cursor: pointer;
      display: block;
      margin: 0;
      overflow: hidden;
      padding: 2px 5px;
      position: relative;
      &:first-child {
        border-top: none;
      }
    }
    .ac_hover {
      background-color: $color-lightest-grey;
    }
  }
}

.account-profile-connected-account__section {
  .account-setting__section {
    div.account-profile__connected-accounts {
      text-transform: none;
    }
  }
}

.profile-page__content {
  .newsletter-info {
    div.social-info__connect {
      float: left;
      width: 100%;
      span.social-info__rstatus {
        input.connect-facebook-button {
          width: auto;
        }
      }
      span.social-info__lstatus {
        @include breakpoint($small-down) {
          margin-bottom: 15px;
          float: left;
        }
      }
    }
    div.new-account__divider {
      display: none;
    }
  }
}

.orders-page__content {
  li.orders-list__table {
    &--order-accordion {
      @include breakpoint($landscape-down) {
        float: none;
      }
    }
  }
}

.order-details-page__content {
  .order-details__item {
    &.gift_container {
      width: 100%;
    }
    .gift_info,
    .gift_wrap {
      text-transform: none;
    }
  }
  .order-info__item {
    .payment-method-name {
      font-weight: bold;
    }
  }
  .order-info__item,
  .order-details__item-info {
    text-transform: none;
  }
  .order-details__subheader {
    margin-top: 20px;
  }
  .order-products {
    .cart-item {
      &__header {
        border-bottom: 1px solid $color-light-gray;
      }
      &.product {
        .cart-item__price {
          font-size: 15px;
          line-height: inherit;
        }
      }
    }
  }
}

.account-section {
  &.orders-page {
    .account-section__sub-header,
    .orders-list__item ul {
      padding: 0 10px;
    }
  }
}

#address_shipping {
  .address-form {
    &__country {
      label {
        display: none;
      }
    }
  }
}

.change-password {
  @include breakpoint($medium-up) {
    .sign-in-page {
      overflow: visible;
    }
  }
}
