.site-footer {
  .site-footer-pc {
    .site-footer-pc {
      &__column:last-child {
        height: auto !important;
      }
    }
  }
  &-pc__column-title {
    font-family: $font--playfair-display;
  }
  .menu-ref {
    @include breakpoint($small-down) {
      padding-left: 10%;
    }
  }
  .menu__list--lvl-1 {
    li.last {
      a {
        line-height: 1.5em;
      }
    }
  }
}

.customer-service {
  &__subtitle,
  &__collapsible-block {
    font-family: $font--playfair-display;
    .collapsible-block__content {
      font-family: $font--roboto;
    }
  }
  &__menu {
    .menu__list {
      .menu__link {
        line-height: 19px;
        height: auto;
      }
    }
  }
}

.site-email-signup,
.site-sms-signup {
  &__title {
    font-family: $font--playfair-display;
  }
  &__terms-conditions__more {
    display: none;
  }
}

.site-header {
  &__utility {
    .block-template-email-signup-block-v1 {
      .site-email-signup__fields {
        display: block;
      }
    }
  }
  &__utility-item {
    &.site-header-utility-not-signed-in {
      @include breakpoint($large-up) {
        width: auto;
        padding: 0 10px;
      }
    }
  }
  &__menu {
    @include breakpoint($landscape-down) {
      height: 85vh;
    }
    &__mobile-util-links {
      padding-bottom: 100px;
      font-size: 12px;
    }
    .menu__item {
      &.menu__item--category {
        @include breakpoint($landscape-up) {
          font-size: 16px;
        }
      }
    }
  }
  div.site-header__utility-logo {
    width: 200px;
  }
}

.store-locator {
  &__form-column--store-type {
    min-width: 270px;
  }
}

.product-name {
  font-family: $font--playfair-display;
}

#store_results {
  .result-number {
    padding: 2px 1px 0 0;
  }
}

.mpp-container {
  .mpp-sort {
    @include breakpoint($small-down) {
      width: 57%;
      padding: 20px 0;
    }
    .mpp-sort-menu {
      min-width: 230px;
      @include breakpoint($small-down) {
        min-width: 178px;
      }
    }
  }
  .mpp-filter {
    @include breakpoint($small-down) {
      width: 43%;
      padding-left: 10px;
    }
  }
}

#site-content {
  .ff-quiz__coverage-stage {
    @include breakpoint($large-up) {
      height: 150px;
    }
  }
  .ff-quiz__carousel-wrapper {
    @include breakpoint($large-up) {
      height: 500px;
      .ff-quiz__slide--2 {
        margin-top: 50px;
      }
    }
  }
  .ff-quiz__answer {
    @include breakpoint($small-down) {
      margin: 0 5px;
      width: 45px;
    }
  }
  .ff-quiz__footer {
    @include breakpoint($small-down) {
      margin-top: 60px;
    }
  }
  .store-locator__form {
    padding: 0;
    &--filters {
      @include breakpoint($small-down) {
        padding: 15px 0 0;
      }
    }
  }
}

.waitlist-form {
  &__email-promotions-section {
    position: relative;
    margin-top: 10px;
    input[type='checkbox'] {
      ~ label {
        &::before {
          content: '';
        }
      }
    }
  }
  &__email-promotions {
    left: 0 !important;
    width: 20px;
    height: 20px;
  }
  &__container {
    h2.waitlist-form__header {
      font-size: 38px;
    }
    .waitlist-form__errors {
      color: $color-error;
    }
    .waitlist-form__email {
      margin-bottom: 10px;
    }
  }
  &__success {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

div.spp_reviews {
  .js-mpp-summary-snippet {
    .p-w-r {
      .pr-snippet {
        .pr-snippet-stars-png {
          div.pr-snippet-rating-decimal:after {
            content: ' из 5';
          }
        }
      }
    }
  }
}

div#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-rd-main-header {
        .pr-rd-review-header-contents:before {
          content: 'Фильтры:';
        }
        .pr-rd-main-header-search-sort {
          .pr-rd-review-header-sorts {
            .pr-rd-sort-group:before {
              content: 'Сортировка: ';
            }
          }
        }
      }
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count:before {
              content: 'Да';
            }
          }
          .pr-helpful-no {
            .pr-helpful-count:before {
              content: 'Нет';
            }
          }
        }
      }
    }
    .pr-review-snapshot {
      .pr-review-snapshot-block-container {
        .pr-review-snapshot-block-cons {
          .pr-review-snapshot-snippets {
            .pr-snippet-stars-reco-stars {
              .pr-snippet-read-and-write {
                .pr-snippet-review-count {
                  line-height: 1;
                  font-size: 26px !important;
                }
                .pr-snippet-write-review-link {
                  @include breakpoint($medium-up) {
                    top: 130px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.product-rating {
  .p-w-r {
    .pr-no-reviews {
      .pr-snippet-stars-reco-inline {
        .pr-snippet-write-review-link {
          @include breakpoint($small-med-only) {
            font-size: 13px !important;
          }
        }
      }
    }
  }
}

#email-signup__form {
  input.form-text {
    font-family: $font--roboto;
  }
}

div.video-library-formatter__items {
  align-items: normal;
}

div.lt-label-mobile {
  display: none;
}

.signup-tout-wrapper {
  .signup-tout-content {
    overflow: visible;
    .email-signup__error {
      max-width: 500px;
    }
  }
}

.gnav-util {
  &__content {
    .change-password {
      .password-field {
        &__rules {
          font-size: 10px;
        }
      }
    }
  }
}

.device-pc {
  .spp-content {
    .sticky-add-to-bag {
      &__section {
        display: flex;
      }
    }
  }
  .site-footer-pc {
    .site-email-signup {
      width: auto;
    }
  }
}

.device-mobile {
  #cboxLoadedContent {
    .waitlist-form {
      height: 500px;
    }
  }
}
