#viewcart section,
.checkout-panel {
  &.checkout-panel--sign-in-main {
    padding: 0 0 20px;
    border-bottom: none;
  }
  &.checkout-panel--shipmethod,
  .checkout-panel--shipmethod {
    div.ship-method-groups {
      display: none;
    }
  }
  .checkout__header {
    padding: 0 0 1em;
  }
  .checkout__sidebar {
    @include breakpoint($small-down) {
      margin: 0 0 2em;
      .checkout-panel__header {
        margin: 0.5em 0;
      }
      .checkout-panel--links {
        border-top: 1px solid $color-light-gray;
      }
    }
  }
  .banner__cart-top {
    padding-bottom: 1.5em;
  }
  &--viewcart {
    div.cart-items {
      &__item {
        padding: 25px 0 10px;
      }
      &__item--price {
        del,
        span {
          color: $color-light-gray;
          @include breakpoint($medium-up) {
            display: block;
          }
        }
      }
      &__item--group-desc-remove {
        @include breakpoint($small-down) {
          margin-bottom: 5px;
        }
      }
      .cart-item {
        border-color: $color-light-gray;
        .product_name {
          a {
            @include breakpoint($small-down) {
              display: block;
              line-height: 1.1;
              margin-bottom: 15px;
            }
          }
        }
        .cart--item__product-name {
          line-height: normal;
        }
        .cart--item__size,
        .cart-item__size {
          margin: 10px 0;
        }
      }
    }
  }
  &--order-summary {
    @include breakpoint($small-down) {
      .checkout-panel__heading {
        margin-bottom: 0;
      }
      .order-summary-content {
        &__label--total {
          font-family: $font--heading;
          line-height: 36px;
          text-transform: uppercase;
          font-size: 20px;
          font-weight: normal;
          margin: 0;
        }
        &__value--total {
          margin-top: 7px;
          font-size: 16px;
        }
        &__label--shipping {
          margin-bottom: 15px;
        }
      }
    }
  }
  .continue-button-wrapper {
    input.checkout__button {
      @include breakpoint($small-down) {
        width: 100%;
      }
    }
  }
  &__content {
    .sticky-checkout-button {
      a.continue-checkout {
        @include breakpoint($small-down) {
          margin: 0;
        }
      }
    }
  }
  .sticky-checkout-button {
    input.btn-mini {
      @include breakpoint($small-down) {
        margin: 0;
      }
    }
  }
  .single-message {
    &:first-child {
      padding-top: 0;
    }
  }
}

@include breakpoint($medium-up) {
  .checkout-index {
    .checkout-panel {
      .new-address {
        div {
          &:first-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .active-panel-shipping {
    div.checkout__content {
      .checkout-panel {
        &.messages-container {
          padding: 0;
          border-bottom: none;
          .messages {
            padding-bottom: 0;
          }
        }
      }
      .checkout-panel--shipping-edit-address {
        padding-bottom: 0;
        .field-container {
          .form-item {
            &--full {
              width: 100%;
            }
            &.phone_label_container {
              margin: 0;
            }
            select {
              margin-bottom: 20px;
            }
          }
        }
      }
      section.checkout-panel--shipmethod {
        &.display {
          border-top: none;
          .checkout-panel__header {
            padding: 0;
          }
        }
      }
      .email-and-sms-promotions {
        & > section {
          padding-bottom: 20px;
          border-bottom: 1px solid $color-light-gray;
          &.no-divide {
            border-bottom: none;
          }
        }
      }
      .checkout-panel--sign-in-main {
        .checkout-panel__header {
          &,
          .checkout-panel__heading {
            font-size: 20px;
            margin-bottom: 0;
            display: block;
          }
        }
      }
      .checkout-panel--shipping {
        section.gift-options-content {
          margin-bottom: 20px;
          padding: 6px 0 16px 0;
        }
      }
    }
  }
}

div.site-footer {
  &__bottom-condensed {
    @include breakpoint($small-down) {
      margin-bottom: 70px;
    }
  }
}

div.checkout__content {
  fieldset {
    &.fs {
      max-width: 100%;
    }
  }
  .checkout-progress-bar {
    border-bottom: none;
    &__list-item {
      float: left;
      width: 33.33%;
      margin: 0;
      padding-bottom: 2px;
      position: relative;
      border-bottom: 1px solid $color-light-gray;
      &--current {
        &:before {
          position: absolute;
          background-color: $black;
          height: 2px;
          content: '';
          width: 100%;
          bottom: -2px;
          left: 0;
        }
      }
    }
  }
  .checkout-panel {
    &--sign-in-main {
      &.finished {
        display: none;
      }
    }
    &--confirmation {
      padding-top: 15px;
    }
    &--viewcart {
      div.cart-items__item {
        &--desc {
          line-height: normal;
          .cart--item__size,
          .cart-item__size {
            margin-top: 15px;
          }
        }
        &--group-desc-remove {
          width: 50%;
        }
        &--group-replenish {
          width: 70px;
          float: right;
        }
        &--qty__label {
          @include breakpoint($small-down) {
            padding-left: 0;
          }
        }
        &:first-child {
          @include breakpoint($medium-up) {
            border-top: 1px solid $color-light-gray;
          }
        }
        &.sample {
          @include breakpoint($medium-up) {
            border-top: 1px solid $color-light-gray;
          }
        }
      }
    }
    &--order-summary {
      div.cart-items {
        &__container {
          @include breakpoint($small-down) {
            border-bottom: 1px solid $color-light-gray;
          }
        }
        &__item {
          &--desc {
            min-height: 50px;
          }
          &--total {
            width: 25%;
          }
        }
        &.products {
          div:last-child {
            @include breakpoint($small-down) {
              border-bottom: none;
            }
          }
        }
      }
      .order-summary-panel {
        &__price-currency {
          font-family: $font--text;
        }
      }
    }
  }
}

#checkout_shipping_panel {
  section.checkout-panel {
    .shipping-additional {
      padding-top: 10px;
      div {
        width: 100%;
        margin-bottom: 10px;
      }
    }
    &--sign-in-main {
      &.finished {
        display: block;
      }
    }
    &--shipping-edit-address {
      .checkout-panel__header {
        margin: 1em 0 0;
        .checkout-panel__heading {
          margin-bottom: 0;
        }
      }
      .co_default {
        text-transform: none;
      }
      .select-address {
        .select-menu {
          margin-bottom: 0;
        }
      }
      @include breakpoint($small-down) {
        .add_new_address {
          padding: 15px 10px 15px;
        }
        .new_shipping_address {
          padding: 0;
          text-transform: none;
        }
        .address-form {
          padding-top: 1em;
          .block {
            padding: 5px 0 8px;
          }
        }
      }
    }
  }
  .gift-options-content {
    &__container {
      @include breakpoint($medium-up) {
        padding-top: 15px;
      }
    }
  }
  .email-and-sms-promotions {
    @include breakpoint($small-down) {
      margin-bottom: 20px;
    }
    section {
      .pc_email_promo_container {
        padding-bottom: 0;
      }
      .sms-mobile {
        margin-top: 10px;
        @include breakpoint($small-down) {
          margin-bottom: 0;
        }
      }
    }
  }
}

div.offer-code__content {
  padding: 45px;
  text-align: center;
  .btn_apply_cc_payment,
  .btn_apply_cod_payment {
    margin: 10px;
  }
}

#checkout_complete {
  h3.checkout__subtitle {
    @include breakpoint($small-down) {
      display: none;
    }
  }
  .active-panel-review {
    input.btn-mini {
      @include breakpoint($small-down) {
        margin: 0;
      }
    }
  }
  .offer-banner-formatter {
    @include breakpoint($small-down) {
      margin: 0 2em 1.5em;
    }
  }
}

#review-panel {
  .checkout-panel {
    .checkout-panel__header {
      margin: 8px 0;
    }
    .messages-container {
      @include breakpoint($small-down) {
        margin: 0 2em;
      }
    }
  }
  section.checkout-panel--order-summary {
    @include breakpoint($small-down) {
      border-top: 1px solid $color-light-gray;
      border-bottom: none;
      margin: 0 2em;
    }
  }
}

#completed_checkout_registration {
  .form-item {
    margin-bottom: 10px;
  }
  .checkout-registration__password {
    margin-bottom: 20px;
    @include breakpoint($small-down) {
      .note {
        font-size: 13px;
      }
    }
    .password {
      label {
        display: block;
      }
    }
    .eye-icon {
      position: absolute;
      width: 18px;
      height: 21px;
      top: 15px;
      right: 12px;
      background-image: url('/media/images/icons/toggle_password_display_icon.jpg');
      background-position: -18px center;
      background-size: 200%;
      background-repeat: no-repeat;
      &.visible {
        background-position: 0 center;
      }
      @include breakpoint($medium-up) {
        display: none;
      }
    }
  }
}

#checkout_signin_new_user,
#checkout_signin {
  @include breakpoint($small-down) {
    div.forgot-link {
      a {
        font-size: 12px;
      }
    }
    .terms,
    .email-promotions,
    .social-login__email-opt-in,
    .social-login__terms {
      label {
        position: relative;
        display: block;
        margin-left: 20px;
        font-size: 12px;
        line-height: normal;
        &::before {
          position: absolute;
          top: 3px;
          left: -20px;
        }
      }
    }
    .social-login-section {
      margin-top: -10px;
      div.social-login__terms {
        font-size: 12px;
        line-height: normal;
        padding-bottom: 0;
      }
      h2 {
        letter-spacing: 0;
        font-size: 12px;
      }
      section {
        margin-top: -4px;
      }
    }
    &-return-ga-submit,
    &-submit,
    &-guest-ga-submit {
      padding: 17px 16px;
      margin: 0;
    }
  }
}

#checkout_samples {
  .samples-content__samples {
    text-align: center;
    div.product-brief__title {
      font-weight: normal;
    }
    .product-list-container {
      a.selected {
        background-color: $color-green;
        border-color: $color-green;
        .icon {
          display: none;
        }
      }
      li.product {
        &:last-child {
          border: 5px solid $color-white;
        }
      }
    }
  }
}

.offer-code-content__one-offer {
  i {
    display: block;
    font-style: normal;
    font-size: 12px;
    margin: 10px 0;
  }
}

.checkout-progress-bar__list-item {
  @include breakpoint($small-down) {
    font-size: 13px;
  }
}

#error_cart\.empty {
  @include breakpoint($small-down) {
    padding: 10px 0;
    border-top: 1px solid $color-light-gray;
    border-bottom: 1px solid $color-light-gray;
  }
}

div.checkout__sidebar {
  .cart-items {
    &__item--group-replenish {
      width: 25%;
      &,
      .cart-items__item--qty {
        float: right;
      }
    }
  }
  div.ship-method-group-label {
    span {
      @include breakpoint($medium-up) {
        float: left;
        clear: both;
        margin: 2px 0;
      }
    }
  }
  .checkout-panel--viewcart {
    .cart-items {
      &__item--total {
        clear: right;
      }
    }
  }
}

#index {
  &.active-panel {
    &-signin,
    &-payment,
    &-shipping,
    &-review {
      header.site-header {
        @include breakpoint($small-down) {
          border-bottom: 1px solid $black;
        }
      }
    }
    &-signin {
      .checkout-panel {
        &--new-account {
          .new-account-content {
            .already-registered-text {
              @include breakpoint($small-down) {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  &.active-panel-signin {
    .checkout-panel {
      &--shipmethod {
        display: none;
      }
      &--offer-code {
        border-top: none;
      }
    }
  }
  &.active-panel-review {
    @include breakpoint($small-down) {
      section.checkout-single-page {
        .checkout-panel {
          border-bottom: none;
        }
        padding: 0;
        div.payment-type {
          border-top: 1px solid $color-light-gray;
          span.radio {
            padding: 1em 2em;
            input[type='radio'] ~ label {
              &::before {
                margin: -2px 8px 0 0;
              }
            }
            img {
              width: 40%;
              right: 2em;
            }
          }
          .group2 {
            span.radio {
              border-bottom: 1px solid $color-light-gray;
            }
          }
        }
        .checkout-panel--links {
          margin: 0 2em 2em;
          border-bottom: 1px solid $color-light-gray;
          border-top: 1px solid $color-light-gray;
          header {
            margin: 8px 0;
          }
        }
        .checkout-progress-bar {
          margin: 1em 2em;
        }
        .checkout-panel--shipping {
          h3.checkout__subtitle {
            margin: 0.5em 0;
          }
        }
        .order-summary-content {
          &__label--total {
            font-family: $font--heading;
            line-height: 36px;
            text-transform: uppercase;
            font-size: 20px;
            font-weight: normal;
          }
          &__value--total {
            font-size: 16px;
          }
          &__label--shipping {
            margin-bottom: 15px;
          }
        }
        .payment-type-field {
          padding: 0 0 2em 0;
        }
        div.total-container,
        div.shipping-address,
        .checkout-panel--review {
          border-bottom: 1px solid $color-light-gray;
        }
        .cart-items {
          &__item {
            &--group-desc-remove {
              width: 50%;
            }
          }
          &__container {
            border-bottom: 1px solid $color-light-gray;
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }
    section {
      .checkout {
        &-panel {
          .checkout-panel__header {
            @include breakpoint($medium-up) {
              margin: 1em 0 0;
              text-align: left;
            }
          }
          &--shipping {
            .shipping-address {
              @include breakpoint($medium-up) {
                margin-bottom: 20px;
                padding-bottom: 10px;
              }
              border-bottom: 1px solid $color-light-gray;
            }
            .shipping-edit__review {
              @include breakpoint($small-down) {
                width: auto;
              }
            }
          }
        }
        &__content {
          #review-panel {
            &.checkout-panel {
              @include breakpoint($medium-up) {
                overflow: unset;
              }
            }
          }
        }
      }
    }
    .offer-banner-formatter {
      @include breakpoint($medium-up) {
        margin: 1.5em 0 0;
      }
    }
  }
  &.active-panel-shipping {
    .checkout-panel__header {
      @include breakpoint($small-down) {
        margin: 12px 0;
      }
    }
    .checkout-panel__heading {
      margin-bottom: 0;
    }
  }
}

#confirm {
  div.checkout__content {
    .checkout__heading {
      line-height: normal;
      margin-bottom: 0;
      font-size: 25px;
    }
    .checkout-panel--confirmation {
      padding-top: 0;
      .checkout-panel__header {
        margin-top: 1.5em;
        @include breakpoint($small-down) {
          display: none;
        }
      }
      .checkout-panel__heading {
        font-size: 23px;
        margin-top: 10px;
      }
    }
    .checkout__header {
      padding: 0;
      width: 80%;
      margin: 0 auto;
    }
    .order-number {
      background-color: $gray;
      border-top: 1px solid $color-lighter-gray;
      font-weight: normal;
      padding: 10px;
      @include breakpoint($small-down) {
        font-size: 14px;
      }
      .link {
        letter-spacing: normal;
        line-height: normal;
        @include breakpoint($small-down) {
          font-size: 15px;
        }
      }
    }
    .confirmation-content__email-coming {
      text-align: center;
      width: 80%;
      margin: 1em auto;
      padding-bottom: 1em;
      @include breakpoint($small-down) {
        font-size: 13px;
      }
    }
    a.button,
    input.button {
      font-size: 13px;
      padding: 16px;
    }
    .checkout-panel--registration {
      border-top: 1px solid $color-lighter-gray;
      margin: 2em 0;
      border-bottom: none;
      .registration_header {
        display: none;
      }
    }
    .registration-content {
      &__heading {
        margin-top: 10px;
        line-height: normal;
        font-family: $font--heading;
        @include breakpoint($medium-up) {
          font-size: 23px;
        }
        @include breakpoint($landscape-up) {
          text-align: center;
        }
      }
      &__pre {
        @include breakpoint($landscape-up) {
          margin-left: 25%;
        }
      }
    }
    .registration-content__pre {
      ul {
        list-style-position: inside;
        margin-top: 15px;
        @include breakpoint($small-down) {
          font-size: 13px;
        }
      }
    }
    .registration-content__create-account {
      border-bottom: 1px solid $color-lighter-gray;
      padding-bottom: 1.5em;
    }
    .checkout-registration__email-value {
      margin-bottom: 20px;
      font-size: 15px;
      @include breakpoint($small-down) {
        font-size: 13px;
      }
      .example-user-email {
        font-weight: normal;
        margin-left: 1em;
        font-size: 15px;
        @include breakpoint($small-down) {
          font-size: 13px;
        }
      }
    }
    .email_promotions {
      p {
        display: none;
      }
    }
    .pc_email_promo_container,
    .checkout-terms {
      @include breakpoint($small-down) {
        font-size: 13px;
      }
    }
    #guarantee-panel {
      display: none;
    }
    .social-login {
      &-section {
        padding: 0;
        margin: 2em 0;
      }
      &__title {
        line-height: normal;
        margin: 18px 0;
        @include breakpoint($small-down) {
          font-size: 21px;
        }
      }
      &__info {
        text-align: center;
        width: 80%;
        margin: 0 auto 2em;
        @include breakpoint($small-down) {
          font-size: 13px;
        }
      }
    }
  }
}

.offer-banner-formatter {
  background-color: $gray;
  .offer-banner-item {
    width: 95%;
    padding: 20px;
  }
}

.active-panel-samples {
  .site-header {
    &__utility-logo {
      @include breakpoint($small-down) {
        min-height: 50px;
      }
      a {
        @include breakpoint($small-down) {
          line-height: 42px;
        }
      }
    }
  }
  header {
    &.site-header {
      @include breakpoint($small-down) {
        border-bottom: 1px solid $black;
        padding-bottom: 10px;
      }
    }
  }
  .site-container {
    @include breakpoint($small-down) {
      margin-top: 0;
    }
    .checkout {
      border-top: none;
    }
  }
}

.active-panel-shipping {
  .delivery-and-options__header {
    margin: 21px 0 17px;
    text-align: center;
  }
  .offer-banner-formatter {
    margin: 1.5em 0 1.5em;
  }
  .gift-options-content {
    border-top: none;
  }
  .sign-in__content {
    &.offer-banner-formatter {
      text-align: left;
      padding: 0 20px;
      margin: 0;
      .checkout-panel__header {
        display: inline-block;
        width: auto;
        margin: 0.5em 0;
        .checkout-panel__heading {
          font-size: 15px;
          font-family: $font--text;
          font-weight: bold;
          text-transform: uppercase;
          margin-bottom: 0;
        }
      }
      .checkout-panel__content {
        display: inline-block;
      }
    }
  }
  section {
    .checkout {
      &__content {
        .checkout-panel {
          &--shipping {
            .email-and-sms-promotions {
              section {
                .checkout-panel__heading {
                  padding-top: 0;
                }
              }
            }
          }
          .click-and-collect-pickpoint {
            @include breakpoint($small-down) {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}

.active-panel-registration {
  .checkout-panel {
    &--registration {
      .registration-content {
        .checkout-registration {
          &__content {
            @include breakpoint($landscape-up) {
              margin-left: 25%;
            }
          }
          &__button {
            input.button {
              @include breakpoint($landscape-up) {
                margin-left: 25%;
              }
            }
          }
          &__email-address {
            text-transform: none;
          }
          &__password {
            .password {
              @include breakpoint($landscape-up) {
                width: 50%;
                margin-left: 25%;
              }
              input[type='password'],
              input[type='text'] {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.messages-container.error {
  #error_offer_criteria_not_met_cc_incentive_benefits {
    display: none;
  }
}

.checkout {
  &-panel--viewcart {
    .cart-items {
      &__item {
        &--price {
          @include breakpoint($small-down) {
            width: 65%;
          }
        }
        &--total {
          @include breakpoint($small-down) {
            width: 35%;
          }
        }
      }
    }
  }
  &--confirmation-page {
    .social-login-section {
      .social-login {
        .social-login {
          &-buttons {
            width: 50%;
            @include breakpoint($small-down) {
              width: 100%;
            }
            margin: 0 auto 10px;
          }
          &__terms {
            display: block;
            @include breakpoint($small-down) {
              font-size: 13px;
            }
          }
          &__email-opt-in {
            text-align: center;
            @include breakpoint($small-down) {
              font-size: 13px;
            }
          }
        }
        .facebook-button {
          width: 100%;
        }
      }
    }
    .checkout-panel {
      &--registration {
        .registration-content {
          .checkout-registration {
            .checkbox {
              .field[type='checkbox'] ~ label::before {
                font-size: initial;
              }
            }
            input[type='checkbox'] ~ label {
              position: relative;
              display: block;
              margin-left: 20px;
              &::before {
                position: absolute;
                top: 6px;
                left: -20px;
              }
            }
            .label-content {
              @include breakpoint($small-down) {
                font-size: 13px;
              }
            }
          }
        }
      }
      &--sign-in-confirmation--header {
        text-align: center;
      }
      &__content {
        &.sign-in-confirmation-content {
          .form-item {
            width: 50%;
            @include breakpoint($medium-up) {
              margin-left: 25%;
            }
            @include breakpoint($small-down) {
              width: 100%;
            }
          }
        }
        .messages {
          @include breakpoint($medium-up) {
            margin-left: 25%;
          }
        }
      }
    }
  }
}

.registration-content {
  .checkout-registration {
    .section-email-promotions {
      margin-bottom: 0;
    }
  }
}
