@import '../../../cremedelamer2_base/scss/_theme-bootstrap.scss';

$font--playfair-display: 'Playfair Display' !default;
$font--roboto: 'Roboto' !default;

$font--neue-haas-unica-pro: $font--roboto;
$font--lamer-headline: $font--playfair-display;
$font--lamer-text: $font--playfair-display;

$font--text: $font--neue-haas-unica-pro, sans-serif !default;
$font--heading: $font--lamer-headline, serif !default;
$font--subheading: $font--lamer-text, serif !default;

// Playfair Display
@font-face {
  font-family: $font--playfair-display;
  src: url('#{$netstorage-font-path}PlayfairDisplay/PlayfairDisplay-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// Roboto
@font-face {
  font-family: $font--roboto;
  src: url('#{$netstorage-font-path}Roboto/Roboto-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
